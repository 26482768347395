#get-started-stage {
  height: 100%;
  overflow-y: auto;
  background: @blue-1;
  background-size: cover;
  display: grid;
  place-items: center;
}

.get-started-card {
  background: @white;
  position: relative;
  width: 730px;
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  &__header {
    padding: 20px;
    padding-left: 80px;

    &__invalid {
      padding: 3rem;

      .h1 {
        margin-bottom: 0;
        color: @blue-dark;
        font-size: 24px;
        position: relative;
        font-weight: normal;
      }
    }
    .h1 {
      margin-bottom: 0;
      color: @blue-dark;
      font-size: 24px;
      position: relative;
      font-weight: normal;
      &:after {
        position: absolute;
        left: -55px;
        top: 0;
        display: block;
        content: "\eaf3";
        font-family: 'mediatool-icons';
        color: @blue-13;
        width: 40px;
        height: 40px;
        font-size: 40px;
      }
    }
  }

  .status-message--error {
    margin-top: -30px;
  }

  &__form {
    form {
      display: grid;
      grid-template-areas: 
      'image fields'
      'image fields'
      'actions actions';
      grid-template-columns: auto 430px;
      padding: 10px 20px;
    }

    &-image {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-area: image;
      padding-left: 10px;
      .mt-image-upload {
        margin-left: 50px;
        margin-top: 48px;

        .crop-modal {
          margin-left: -40px;
          margin-top: -31px;

          .m-r {
            float: left;
          }
        }

        .mt-image-upload__image-upload-label {
          margin-top: 5px;
        }

        &__uploaded-image {
          width: 160px;
          height: 160px;
          margin-left: -10px;
          margin-top: 30px;
        }
      }
    }

    &-fields {
      grid-area: fields;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 10px;
      .input,
      .input input {
        width: 400px;
        max-width: 400px;
        margin-bottom: 0;
      }
      .input:not(div.input.input-password) {
        margin-bottom: 28px;
      }

      .input-header {
        display: flex;
        font-weight: bold;
        justify-content: start;

        .toggle-password {
          flex-grow: 1;
          height: 16px;
          max-width: 16px;
          margin-left: 10px;
          position: relative;
          color: @blue-dark;
          background-image: @open-eye-icon ;
          cursor: pointer;
        }
        .hide {
          background-image: @closed-eye-icon;        
        }
      }
    }

    &-actions {
      grid-area: actions;
      padding-right: 10px;
      margin: 10px 0;
      display: flex;
      width: 100%;
      .checkbox {
        display: flex;
        align-items: center;
        padding-left: 20px;
        width: 85%;
        label {
          padding-left: 5px;
          margin-right: 10px;
        }
      }
      button {
        width: 15%;
      }
    }
  }

  .red-warning {
    flex-grow: 2;
    font-weight: normal;
    color: red;
    text-align: right;
  }

  .password-strength > p {
    font-size: 12px !important;
  }
}

.no-org-error {
  &__wrapper {
    display: grid;
    place-items: center;
    height: 100vh;
  }
  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    .h1 {
      margin-top: 50px;
    }
  }
}


.get-started-step {
  width: 540px;
  border: none;
  padding: 0 20px 20px;
  .border-radius(0 0 6px 6px);
  background: @white;
  color: @blue-dark;

  .h5 {
    color: @blue-dark;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
  }

  .intro-text {
    color: @blue-dark;
    margin-top: 5px;
    font-size: @font-size-medium;
    padding-bottom: 10px;
    border-bottom: 1px dashed @gray-4;
    margin-bottom: 15px;
  }

  form {
    overflow: hidden;
    margin-bottom: 0;
  }

  .upload-box {
    border: 1px dashed @gray-3;
    min-height: 80px;
    margin-top: 4px;
    .border-radius(2px);

    .upload-link {
      display: block;
      text-align: center;
      margin-top: 27px;
    }
  }

  .add-colleague {
    [class^="icon-"],
    [class*=" icon-"] {
      position: relative;
      top: 1px;
    }
    margin-bottom: 10px;
  }

  .get-started-actions {
    .clearfix();
    margin-top: 20px;
  }
}

.get-started-step__image-placeholder {
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px dashed @blue-3;
}

.get-started-step__image {
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border: none;
}

.org-type-selector {
  border: 2px solid @grey-13;
  background-color: transparent;
  margin-left: 10px;
  float: left;
  height: 240px;
  width: 230px;
  .border-radius(4px);
  color: @grey-20;
  text-align: center;
  padding: 20px 10px 20px;
  transition: all 200ms;
  cursor: pointer;
  position: relative;

  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 50px;
    display: block;
    margin-bottom: 5px;
  }

  .h3 {
    color: @grey-20;
    margin: 10px 0 5px;
  }

  &.is-active {
    border: 2px solid @blue-dark;
    background-color: @blue-1;
    color: @blue-dark;

    .h3 {
      color: @blue-dark;
    }

    &:after {
      content: "\ea38";
      font-family: 'mediatool-icons';
      position: absolute;
      background-color: @white;
      border-radius: 24px;
      top: -12px;
      right: -12px;
      width: 24px;
      font-size: 24px;
      height: 24px;
    }
  }

  .paragraph {
    border: none;
    padding: 0;
  }
}