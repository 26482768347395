@font-path:                     "/static/fonts";
@reporting-font-path:           "/static/fonts";

@short-animation-time:          0.320s;
@long-animation-time:           2s;

@sidebar-margin-bottom:         10px;
@scrollbar-width:               8px;
@scrollbar-height:              8px;
@header-height:                 60px;
@filter-height:                 64px;
@toolbar-height:                44px;
@dashboard-toolbar-height:      56px;
@toolbox-header-height:         61px;
@toolbox-footer-height:         44px;
@company-menu-height:           56px;
@application-menu-height:       272px;
@user-menu-height:              45px;
@footer-height:                 45px;
@widgets-height:                102px;
@approval-height:               35px;
@calendar-header-height:        58px;
@support-menu-height:           55px;
@header-fields-height:          68px;
@mediaplan-controls-height:     30px;
@print-frontpage-height:        64px;
@mediaplan-widget-height:       102px;
@campaign-menu-offset:          @header-height + @application-menu-height + @sidebar-margin-bottom;
@flatview-offset:               @filter-height + @header-fields-height + @footer-height + 1px;
@flatview-offset-campaign:      @flatview-offset + @print-frontpage-height;
@flatview-offset-targets:       @flatview-offset + @mediaplan-widget-height;
@calendar-offset:               @filter-height;
@calendar-offset-campaign:      @calendar-offset + @print-frontpage-height;
@calendar-offset-targets:       @calendar-offset + @print-frontpage-height + @mediaplan-widget-height;
@material-offset:               @filter-height;
@material-offset-campaign:      @material-offset + @print-frontpage-height;
@material-offset-targets:       @material-offset + @print-frontpage-height + @mediaplan-widget-height;
@intell-offset:                 @filter-height;
@intell-offset-campaign:        @intell-offset + @print-frontpage-height;
@toolbox-wfooter-offset:        @toolbox-footer-height;
@dashboard-offset:              @filter-height;
@dashboard-offset-campaign:     @dashboard-offset + @print-frontpage-height;
@dashboard-offset-targets:      @dashboard-offset + @print-frontpage-height + @mediaplan-widget-height;
@mediaplan-with-target-offset:  @calendar-offset + @mediaplan-widget-height;
@target-offset:                 @filter-height;

@campaign-menu-height:          calc(~"100%" - @campaign-menu-offset);
@campaign-list-height:          calc(~"100%" - 39px);
@campaign-list-height-active:   calc(~"100%" - 10px);
@content-height:                calc(~"100%" - @header-height);
@content-height-padded:         100%;

@flatview-height:               calc(~"100%" - @flatview-offset);
@flatview-results-height:       calc(~"100%"- 109px);
@flatview-campaign-height:      calc(~"100%" - @flatview-offset-campaign);
@flatview-targets-height:       calc(~"100%" - @flatview-offset-targets);
@flatview-campaign-targets-height: calc(~"100%" - 344px);
@flatview-traffic-height:       calc(~"100%" - 108px);
@flatview-execution-height:    calc(~"100%" - @filter-height);

@handson-height:                calc(~"100%" - @toolbar-height);
@calendar-height:               calc(~"100%" - @calendar-offset);
@calendar-campaign-height:      calc(~"100%" - @calendar-offset-campaign);
@calendar-targets-height:       calc(~"100%" - @calendar-offset-targets);
@calendar-height-inner:         calc(~"100%" - @toolbar-height);

@material-inner-height:         calc(~"100%" - @toolbar-height);
@material-height:               calc(~"100%" - @material-offset);
@material-campaign-height:      calc(~"100%" - @material-offset-campaign);
@material-target-height:        calc(~"100%" - @material-offset-targets);

@intelligence-height-inner:     calc(~"100%" - @toolbar-height);
@intelligence-height:           calc(~"100%" - @intell-offset);
@intelligence-campaign-height:  calc(~"100%" - @intell-offset-campaign);
@files-height:                  calc(~"100%" - @toolbar-height);

@dashboard-height-inner:        calc(~"100%" - @dashboard-toolbar-height);
@dashboard-height:              calc(~"100%" - @dashboard-offset);
@dashboard-campaign-height:     calc(~"100%" - @dashboard-offset-campaign);
@dashboard-target-height:       calc(~"100%" - @dashboard-offset-targets);
@dashboard-empty-height:        calc(~"100%" - @header-height);

@mediaplan-with-target-height:  calc(~"100%" - @mediaplan-with-target-offset);

@target-height-inner:           calc(~"100%" - @header-height);
@target-height:                 calc(~"100%" - @target-offset);

@toolbox-height:                calc(~"100%" - @toolbox-header-height);
@toolbox-height-wfooter:        calc(~"100%" - @toolbox-wfooter-offset);

@with-toolbar-height:           calc(~"100%" - @toolbar-height);

@tablets: ~"only screen and (max-width: 1024px)";
@small-tablets: ~"only screen and (max-width: 1023px)";
//@blue: #0BAFFF;

@open-eye-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Eview%3C/title%3E%3Cg class='nc-icon-wrapper' fill='none' stroke='%23212121' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M1.373,13.183a2.064,2.064,0,0,1,0-2.366C2.946,8.59,6.819,4,12,4s9.054,4.59,10.627,6.817a2.064,2.064,0,0,1,0,2.366C21.054,15.41,17.181,20,12,20S2.946,15.41,1.373,13.183Z'/%3E%3Ccircle cx='12' cy='12' r='4' stroke='%23212121'/%3E%3C/g%3E%3C/svg%3E%0A");
@closed-eye-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Ehide%3C/title%3E%3Cg class='nc-icon-wrapper' fill='none' stroke='%23212121' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M1.373,13.183a2.064,2.064,0,0,1,0-2.366C2.946,8.59,6.819,4,12,4s9.054,4.59,10.627,6.817a2.064,2.064,0,0,1,0,2.366C21.054,15.41,17.181,20,12,20S2.946,15.41,1.373,13.183Z'/%3E%3Ccircle cx='12' cy='12' r='4' stroke='%23212121'/%3E%3Cline x1='2' x2='22' y1='22' y2='2' stroke='%23212121'/%3E%3C/g%3E%3C/svg%3E%0A");

