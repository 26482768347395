.animated {
  -webkit-animation-duration: @long-animation-time;
  animation-duration: @long-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  40%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    opacity: 0.8;
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
    opacity: 0.8;
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
}


@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
}

.icon-spin {
  -webkit-animation: fa-spin 0.4s infinite linear;
  animation: fa-spin 0.4s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.crop-modal-transition-enter {
  opacity: 0;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.crop-modal-transition-enter.crop-modal-transition-enter-active {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.crop-modal-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.crop-modal-transition-leave.crop-modal-transition-leave-active {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.campaign-list-transition-enter {
  opacity: 0;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.campaign-list-transition-enter.campaign-list-transition-enter-active {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.campaign-list-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.campaign-list-transition-leave.campaign-list-transition-leave-active {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

.campaign-menu-transition-enter {
  opacity: 0;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.campaign-menu-transition-enter.campaign-menu-transition-enter-active {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.campaign-menu-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.campaign-menu-transition-leave.campaign-menu-transition-leave-active {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

.org-menu-transition-enter {
  opacity: 0;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.org-menu-transition-enter.org-menu-transition-enter-active {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.org-menu-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.org-menu-transition-leave.org-menu-transition-leave-active {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

.guide-items-transition-enter {
  opacity: 0;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.guide-items-transition-enter.guide-items-transition-enter-active {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.guide-items-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @short-animation-time;
  animation-duration: @short-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.guide-items-transition-leave.guide-items-transition-leave-active {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

.guide-steps-transition-enter {

}

.guide-steps-transition-enter.guide-steps-transition-enter-active {

}

.guide-steps-transition-leave {
  max-height: 700px;
}

.guide-steps-transition-leave.guide-steps-transition-leave-active {
  overflow-y: hidden;
  max-height: 0;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.stage-loader-transition-enter {
  opacity: 0;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.stage-loader-transition-enter.stage-loader-transition-enter-active {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.stage-loader-transition-leave {
  opacity: 1;
  -webkit-animation-duration: @long-animation-time;
  animation-duration: @long-animation-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.stage-loader-transition-leave.stage-loader-transition-leave-active {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}


@-webkit-keyframes tdExpandIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes tdExpandIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes tdExpandInBounce {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  70% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
}
@keyframes tdExpandInBounce {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  70% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
}
.tdExpandIn {
  -webkit-animation-name: tdExpandIn;
          animation-name: tdExpandIn;
}
.tdExpandInBounce {
  -webkit-animation-name: tdExpandInBounce;
          animation-name: tdExpandInBounce;
}
/* Expand Exits */
@-webkit-keyframes tdExpandOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes tdExpandOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@-webkit-keyframes tdExpandOutBounce {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes tdExpandOutBounce {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
.tdExpandOut {
  -webkit-animation-name: tdExpandOut;
          animation-name: tdExpandOut;
}
.tdExpandOutBounce {
  -webkit-animation-name: tdExpandOutBounce;
          animation-name: tdExpandOutBounce;
}

@-webkit-keyframes tdFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tdFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes tdFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes tdFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tdFadeIn {
  -webkit-animation-name: tdFadeIn;
          animation-name: tdFadeIn;
}
.tdFadeOut {
  -webkit-animation-name: tdFadeOut;
          animation-name: tdFadeOut;
}

@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
            transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
            transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
            transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
            transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
            transform: translateY(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
            transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
            transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
            transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
            transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
            transform: translateY(-6px) rotate(-1.2deg);
  }
}



.wobble-ver-left {
	-webkit-animation: wobble-ver-left 0.320s both;
	        animation: wobble-ver-left 0.320s both;
}