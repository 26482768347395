.mt-image-upload {
  &__image-upload {
    .image-editor {
      height: 100%;
      width: 100%;

      .crop-modal {
        position: absolute;
        z-index: 0;
        background-color: white;
      }

      .upload-button {
        height: 140px;
        width: 140px;
        text-align: center;
        border: 2px @blue-13 dashed;
        border-radius: 50%;
        z-index: -1;

        &::before {
          display: grid;
          place-items: center;
          content: "\ea1c";
          font-family: 'mediatool-icons';
          color: @blue-13;
          border-radius: 50%;
          font-size: 90px;
          margin-top: -4px;
        }

        .icon-add-file {
          visibility: hidden;
        }
      }
    }

    &-label {
      text-align: center;
      margin-top: -10px;
    }
  }

  &__uploaded-image {
    height: 140px;
    width: 140px;
    object-fit: cover;
  }

  .mt-text-input__error {
    justify-content: start;
  }
}
