#main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: underline;
  color: #E5856E;

  &:hover {
    text-decoration: none;
  }
}

.page {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center center;
  background: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F1ED;
  overflow-x: hidden;
}

.page__wrapper {
  width: 900px;
  height: 600px;
  background: @white;
  display: flex;
  .flex-grow(0);
  .flex-shrink(0);
  .border-radius(6px);
  overflow: hidden;
}

.login-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 10;

  .signal {
    border: 5px solid @blue;
    border-radius: 100px;
    height: 100px;
    left: 50%;
    margin: -50px 0 0 -50px;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 100px;

    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
  }

  @-webkit-keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
}

.page__right-col {
  display: flex;
  width: 450px;
  padding: 0 72px;
  .flex-grow(1);
  .flex-shrink(0);
  align-items: center;
  position: relative;
}

.logo {
  background: url("/static/images/camphouse-logo-4.svg") no-repeat;
  background-size: 188px 43px;
  height: 43px;
  width: 188px;
  margin: 0;
  position: absolute;
  top: -95px;
  left: 130px;
}

.message-center {
  top: 120px;
  position: absolute;
  width: 305px;
  padding: 10px 15px 15px;
  background: #2d353a;
  color: #fff;
  .border-radius(3px);
  font-size: @font-size-base;
  z-index: 1;

  #get-started-stage & {
    top: -80px;
    background: @red;
    width: 540px;
  }

  .message-center__message {
    float: left;
    width: 220px;
    line-height: 22px;
    word-wrap: break-word;

    #get-started-stage & {
      width: 465px;
    }
  }

  .message-center__icon {
    top: 1px;
    position: relative;
    font-size: @font-size-large;
    left: -5px;
    margin-right: 6px;
    float: left;
  }

  .message-center__close {
    top: 5px;
    position: relative;
    font-size: @font-size-large;
    cursor: pointer;
    float: right;
    font-size: @font-size-base;
  }
}

.form {
  overflow: hidden;
  width: 100%;
}

.form__header {
  margin-bottom: 10px;
  padding-bottom: 10px;
  overflow: hidden;

  .h1 {
    font-size: @font-size-large;
    font-weight: normal;
    margin: 0;
    color: #052538;
    font-style: normal;
  }

  p {
    margin: 5px 0 0;
    color: @gray-5;
    font-size: @font-size-medium;
    float: left;
  }
}

.form__link {
  float: left;
  margin-top: 5px;

  .back {
    color: #E5856E;
    text-decoration: underline;
  }
}

.email-notice {
  position: absolute;
  background: fade(@gray-8, 95%);
  color: @white;
  padding: 15px;
  font-size: @font-size-medium;
  top: 35px;
  width: 305px;
  .border-radius(4px);
  line-height: 1.4;

  &.disposable {
    top: 110px;
  }

  &:after {
    bottom: -10px;
    left: 138px;
    border-top: 10px solid fade(@gray-8, 90%);
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  p {
    margin: 0 0 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}


.loginput {
  border: 1px solid #052538;
  width: 305px;
  float: left;
  background: @white;
  .border-radius(3px);

  transition: border-color ease 100ms;

  &--error {
    border-color: @red;
  }

  span[class^="icon-"],
  span[class*="icon-"] {
    font-size: @font-size-large;
    display: block;
    float: left;
    margin-top: 9px;
    margin-left: 10px;
    color: #052538;
  }

  input {
    height: 36px;
    padding: 0 10px;
    font-size: 16px;
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    width: 270px;
    color: #052538;
    font-weight: bold;
    background: #ffffff;

    &:focus {
      outline: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #052538 !important;
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      box-shadow: 0 0 0px 1000px white inset !important;
    }
  }

  &.invalid {
    border: 1px solid @red;

    &:after {
      content: 'Invalid Email address';
      color: @red;
      font-size: @font-size-small;
      margin-left: 38px;
      padding-bottom: 4px;
      display: inline-block;
    }
  }
}

.loginput--with-link {
  input {
    width: 200px;
  }
}

.remember {
  margin-top: 10px;
  float: left;
  clear: both;
  cursor: pointer;
  color: @gray-6;
  font-size: @font-size-medium;

  .icon-round {
    font-size: 16px;
    color: @gray-6;
    position: relative;
    top: 3px;
    margin-right: 4px;
    margin-left: 2px;
  }

  .icon-round-check {
    font-size: 16px;
    color: @green;
    position: relative;
    top: 3px;
    margin-right: 4px;
    margin-left: 2px;
  }
}

.forgot {
  border-left: 1px solid #d2d2d2;
  padding-left: 10px;
  text-decoration: none;
  color: @gray-4;
  font-size: @font-size-base;
}

code {
  display: none;
}

.quick-login-list {
  list-style: none;
  padding: 0;
  margin-top: 40px;
  clear: both;

  li {
    text-align: left;
    display: block;
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 5px;
    border: 2px dashed transparent;
    background: #ffffff;
    font-weight: bold;
    font-style: normal;
    display: flex;
    transition: all 500ms ease;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }

    &:hover {
      border-color: #052538;
      border-style: dashed;
      cursor: pointer;
    }
  }
}

.quick-login__user {
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: @white;
}

@media (max-width: 767px) {
  .page__wrapper {
    position: static;
    width: 320px;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: visible;
  }

  .email-notice {
    padding: 10px;
    font-size: @font-size-small;
    top: 1px;
    width: 260px;
    line-height: 1.4;

    &:after {
      left: 115px;
    }

    &.disposable {
      top: 60px;
    }
  }

  .error {
    top: 20px;
    width: 260px;
    line-height: 1.5;

    .error__message {
      width: 180px;
      line-height: 22px;
    }

    .icon-warning {
      display: none;
    }
  }

  .loginput--with-link {
    input {
      width: 155px;
    }
  }
}

.status-message {
  padding: 10px;

  background-color: @white;
  border-left: 3px solid @gray-6;
  color: @gray-6;

  margin-bottom: 10px;

  &--error {
    border-color: @red;
    color: @red;
  }
  &--success {
    border-color: @green;
    color: @green;
  }
}

.otp-form {
  .mt-otp-input__digit {
    width: 38px;
    height: 60px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.login-image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;

  &--top {
    background-image: url("/static/images/camphouse-top.png");
    top: -100px;
    left: 55%;
    width: 500px;
    height: 350px;

    @media (max-width: 767px) {
      width: 100%;
      height: 240px;
      left: 0;
      transform: none;
      background-position: center;
    }
  }

  &--bottom-left {
    background-image: url("/static/images/camphouse-bottom-left.png");
    bottom: 100px;
    left: 100px;
    width: 250px;
    height: 250px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &--bottom-right {
    background-image: url("/static/images/camphouse-bottom-right.png");
    bottom: 0;
    right: 250px;
    width: 250px;
    height: 156px;

    @media (max-width: 767px) {
      right: 20px;
      width: 200px;
      height: 120px;
      background-position: right bottom;
    }
  }
}

// Add this to ensure the login form remains above the images
.login {
  position: relative;
  z-index: 2;
}